<template>
  <v-app class>
    <div class="row">
      <div class="col-md-12 ml-auto mr-auto">
        <v-container>
          <div class="mb-4 mt-8 d-flex ">
            <div>
              <v-alert v-model="alert"
                       border="left"
                       dark
                       dismissible
                       class="alert"
                       :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}">
                {{alertMessage}}
              </v-alert>
            </div>
            <div class="col-md-11 text-center">
              <h1>Pending Income Tax Band</h1>
            </div>
          </div>

          <div v-if="loadingData" class="text-center">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </div>
           <v-data-table v-else
                         :headers='headers'
                         :items="incomeTaxBand"
                         item-key="id" :width="200">
                    <template v-slot:[`item.amount`]="{ item }" >
                      {{item.amount | formatMoney}}
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <div class="d-flex">
                        <Button :btnType="'Submit'" :color="'success'" :label="'Approve'" @onClick="ApproveItem(item)" class="mr-2" />
                        <Button :btnType="'Cancel'" :label="'Reject'" @onClick="RejectItem(item)" class="mr-2" />
                    </div>
                    </template>
           </v-data-table>

             <!-- reject dialog  -->
              <v-dialog v-model="rejectDialog" persistent max-width="400">
                <v-card>
                  <v-card-title class="headline text-center"><h3>Reason for rejecting</h3></v-card-title>
                  <v-divider class="mx-4 mb-5"></v-divider>
                  <v-card-text class="mt-5">
                    <v-form ref="commentForm" v-model="valid">
                      <TextField  v-model="formData.comments" :label="'Comment'"/>
                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <Button
                      :label="'Reject'"
                      :btnType="'Submit'"
                      @onClick="onReject"
                      :isLoading="loading"
                      :disabled="!valid"
                      class="mr-4"
                    />
                    <v-btn color="green darken-1" text @click="rejectDialog = false">Close</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog >
            <!-- return dialog  -->
        </v-container>
      </div>
    </div>

    <ConfirmationDialog :title="'Are you sure you want to submit?'" :isLoading="loadingData" :dialog="dialog" :btnTitle="'Yes'" @close="dialog = false" @btnAction="onSave"/>

    <ConfirmationDialog :title="'Are you sure you want to Approve?'" :isLoading="loadingData" :dialog="dialogApprove" :btnTitle="'Yes'" @close="dialogApprove = false" @btnAction="onApprove"/>
  </v-app>
</template>
<script>
import Button from '@/components/ui/form/Button.vue'
import ConfirmationDialog from '@/components/ui/confirmationDialog.vue'
import { requestService } from '@/services'
import TextField from '@/components/ui/form/TextField'
export default {
  components: {
    TextField,
    Button,
    ConfirmationDialog
  },
  data () {
    return {
      formData: {
        rate: '',
        amount: '',
        order: '',
        comments: ''
      },
      editedData: {
        id: 0,
        rate: 0,
        amount: 0,
        order: 0
      },
      pay: [],
      approval: [],
      loadingData: false,
      loading: false,
      dialog: false,
      dialogApprove: false,
      rejectDialog: false,
      valid: false,
      incomeTaxBand: [],
      alertMessage: '',
      alert: false,
      alertType: '',
      headers: [
        {
          text: 'Rate',
          value: 'rate'
        },
        {
          text: 'Amount',
          value: 'amount'
        },
        {
          text: 'Order',
          value: 'order'
        },
        {
          text: 'Actions',
          value: 'actions'
        }
      ]
    }
  },
  computed: {
    // user () {
    //   return this.$store.getters.users
    // }
  },
  methods: {
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
      setTimeout(() => {
        this.alert = false
      }, 5000)
    },
    getPendingIncomeTaxBands () {
      this.loadingData = true
      requestService.getAllPendingIncomeTaxBand().then(result => {
        this.incomeTaxBand = result.data.items
        console.log('IncomeTax', this.incomeTaxBand)
      }).catch(error => {
        console.log(error)
      })
      this.loadingData = false
    },

    edit (item) {
      this.editedData.id = item.id
      this.editedData.rate = item.rate
      this.editedData.amount = parseFloat(item.amount)
      this.editedData.order = item.order
      this.$refs.incomeTaxBandModal.toggleModal()
    },
    RejectItem (item) {
      this.rejectDialog = true
      this.formData.id = item.id
    },
    onReject () {
      this.loading = true
      const data = {
        Comments: this.formData.comments,
        Status: 2,
        Id: this.formData.id
      }
      requestService.approveIncomeTaxBand(data).then(() => {
        this.showAlertMessage('Income Tax Band successfully Rejected', 'success')
        this.loading = false
        this.rejectDialog = false
        this.getPendingIncomeTaxBands()
      }).catch(error => {
        console.log(error)
        this.loading = false
      })
    },
    cancel () {
      this.$refs.incomeTaxBandModal.toggleModal()
    },
    update () {
      this.loading = true
      const data = {
        Id: this.editedData.id,
        Rate: parseInt(this.editedData.rate),
        Amount: parseInt(this.editedData.amount),
        Order: parseInt(this.editedData.order)
      }
      requestService.updateIncomeTaxBand(data).then(() => {
        this.showAlertMessage('Income Tax Band successfully Updated', 'success')
        this.loading = false
        this.getPendingIncomeTaxBands()
        this.cancel()
      }).catch(error => {
        console.log(error)
        this.loading = false
      })
    },
    onSave () {
      this.loadingData = true
      console.log('Ok')
      const data = {
        Rate: this.formData.rate,
        Amount: this.formData.amount,
        Order: this.formData.order
      }
      console.log("I'm Here")

      requestService.addIncomeTaxBand(data).then(() => {
        this.showAlertMessage('Income Tax Band successfully Added', 'success')
        this.getPendingIncomeTaxBands()
      }).catch((error) => {
        console.log('Error: ', error)
        this.showAlertMessage('Income Tax Band failed', 'error')
        this.loadingData = false
        this.dialog = false
      }).finally(() => {
        this.loadingData = false
        this.dialog = false
        this.onReset()
      })
    },
    ApproveItem (item) {
      this.approval = item
      this.dialogApprove = true
    },
    onApprove () {
      this.loadingData = true
      console.log('Ok')
      const data = {
        Comments: '',
        Status: 1,
        Id: this.approval.id
      }
      console.log('Approve is Here')

      requestService.approveIncomeTaxBand(data).then(() => {
        this.showAlertMessage('Income Tax Band successfully Approved', 'success')
        this.getPendingIncomeTaxBands()
      }).catch((error) => {
        console.log('Error: ', error)
        this.showAlertMessage('Approval Income Tax Band failed', 'error')
        this.loadingData = false
        this.dialogApprove = false
      }).finally(() => {
        this.loadingData = false
        this.dialogApprove = false
        this.onReset()
      })
    },
    onReset () {
      this.$refs.IncomeTaxBandForm.reset()
      this.formData.amount = ''
      this.formData.rate = ''
      this.formData.order = ''
    },
    openDeleteModal (item) {
      this.data = item
      console.log(item)
      this.dialog = true
    }

  },
  mounted () {
    this.getPendingIncomeTaxBands()
  }
}
</script>
<style scoped>
  .search-btn {
    background-color: #e6e3e3;
    height: 40px;
    border-radius: 3px !important;
  }

  .alert {
    position: fixed;
    z-index: 9999;
    top: 5px;
    min-width: 50%;
  }

  .alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }

  .alert-error {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }

  .row-pointer >>> tbody tr :hover {
    cursor: pointer;
  }

  .iconAvatar {
    width: 122px;
  }

  .text-caption {
    font-weight: 600;
    font-size: 14px !important
  }
  .sticky-col {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: 0;
  }
  .cursor {
    cursor: pointer;
  }

    .cursor:hover {
      color: blue
    }

    .cursor .tooltiptext {
      visibility: hidden;
      width: 80px;
      background-color: black;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      padding: 2px 0;
      font-size: 10px;
      /* Position the tooltip */
      position: absolute;
      z-index: 1;
    }

    .cursor:hover .tooltiptext {
      visibility: visible;
    }
</style>
